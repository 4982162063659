// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bj_k5";
export var caseStudyBackground__lineColor = "bj_kX";
export var caseStudyHead = "bj_k7";
export var caseStudyHead__imageWrapper = "bj_kV";
export var caseStudyProjectsSection = "bj_k6";
export var caseStudyQuote__bgLight = "bj_k3";
export var caseStudyQuote__bgRing = "bj_k2";
export var caseStudyVideo__ring = "bj_k8";
export var caseStudy__bgDark = "bj_kT";
export var caseStudy__bgLight = "bj_kS";